<template>
  <div class="wrapper-main">
    <div class="wrapper-content">
      <AccountNumber></AccountNumber>
      <div class="ReferralLinks-content clearfix">
        <div class="select">
          <p :class="{ 'long-text': ['pt', 'es', 'fr', 'vi'].includes(lang) }">{{ $t('referralLinks.campLang') }}</p>
          <el-select v-model="language" @change="languageSelectChange" data-testid="language">
            <el-option
              v-for="item in languageList"
              :key="item.language"
              :label="item.language"
              :value="item.value"
              :data-testid="item.language"
            ></el-option>
          </el-select>
        </div>
        <div class="info-box">
          <div class="copy-line" v-if="accountID">
            <div
              v-for="(link, index) in linkList"
              v-bind:key="link.name"
              :class="{ active: active == index }"
              @click="active = index"
              :data-testid="link.name"
            >
              <p>{{ link.name }}</p>
              <!-- AddToAny END -->
              <div class="copy">
                <div class="img">
                  <el-tooltip placement="bottom-start" popper-class="linkTooltip" visible-arrow="true">
                    <div slot="content">
                      <div class="qrcode-share">
                        <div class="top">
                          <vue-qrcode :color="color" :value="link.url || GLOBAL_REFERRAL_SITE" />
                        </div>
                        <!-- AddToAny BEGIN -->
                        <div class="a2a-kit a2a_kit_size_32 a2a_default_style" :data-a2a-url="link.url">
                          <a class="a2a_button_whatsapp"></a>
                          <a class="a2a_button_line"></a>
                          <a class="a2a_button_wechat"></a>
                          <a class="a2a_button_twitter"></a>
                          <a class="a2a_button_telegram"></a>
                          <a class="a2a_button_facebook"></a>
                          <a class="a2a_button_skype"></a>
                          <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
                        </div>
                        <component :is="'script'" src="https://static.addtoany.com/menu/page.js" async></component>
                        <!-- AddToAny END -->
                        <div class="bottom">
                          {{ $t('referralLinks.Share') }}...
                          <img src="@/assets/images/referralLinks.png" @click="onCopyImg(index)" alt="" />
                        </div>
                      </div>
                    </div>
                    <vue-qrcode :color="color" :value="link.url || GLOBAL_REFERRAL_SITE" :class="index" />
                  </el-tooltip>
                </div>
                <div class="url">
                  <div class="fl">
                    <p>{{ link.url }}</p>
                  </div>
                  <div class="fr" @click="onCopy(link.url)" :data-testid="'copy-' + link.name">
                    <span>{{ $t('referralLinks.cpy') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ib_agreement" v-if="isSigned && !isSubUser">
          <el-row style="flex" justify="space-between">
            <el-col :xs="24" :md="6" :lg="6" :xl="6" class="title"
              ><span @click="previewAgreement">{{ $t('agreement.IBAgreement') }}</span></el-col
            >
            <el-col :xs="24" :md="18" :lg="18" :xl="18" class="account">
              <span class="rebate_account"
                >{{ $t('agreement.signed', { accountID: accountID, signedTime: signedTime }) }}
              </span>
              <download-button class="button" :callback="downLoad" icon="el-icon-download"> </download-button>
            </el-col>
          </el-row>
        </div>
        <vDialog :show.sync="visible" width="90%" class="dialog">
          <template slot="header">
            <div class="close_icon"><i class="el-icon-close" @click="visible = false"></i></div>
          </template>
          <div class="agreement" v-loading.lock="pdfScreenLoading">
            <vue-pdf-embed  @progress="loadProgress" v-if="showPreViewPdf" ref="previewPdfRef" :source="fileUrl" :width="pdfAreaWidth" />
          </div>
        </vDialog>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import AccountNumber from '@/components/form/AccountNumber';
import DownloadButton from '@/components/download/DownloadButton';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed.js';
import vDialog from '@/components/vDialog.vue';
import { dataFormatter } from '@/util/time.js';
import { apiAgreement } from '@/resource';
import axios from 'axios';
import Bus from '@/assets/js/Bus.js';
export default {
  name: 'ReferralLinks',
  components: { AccountNumber, VueQrcode, DownloadButton, vDialog, VuePdfEmbed },
  data() {
    return {
      language: '',
      languageList: {
        English: { language: this.$t('common.lang.english'), value: '' },
        Chinese: { language: this.$t('common.lang.chinese'), value: 'cn' },
        French: { language: this.$t('common.lang.french'), value: 'fr' },
        Thai: { language: this.$t('common.lang.thai'), value: 'th' },
        German: { language: this.$t('common.lang.german'), value: 'de' },
        Spanish: { language: this.$t('common.lang.spanish'), value: 'es' },
        Malay: { language: this.$t('common.lang.malay'), value: 'my' },
        Vietnamese: { language: this.$t('common.lang.viet'), value: 'vn' },
        Indonesian: { language: this.$t('common.lang.indo'), value: 'id' },
        Arabic: { language: this.$t('common.lang.arabic'), value: 'ar' }
      },
      linkList: {
        liveAccountLink: { name: this.$t('referralLinks.liveAcc'), url: '' },
        demoAccountLink: { name: this.$t('referralLinks.demoAcc'), url: '' },
        homePageLink: { name: this.$t('referralLinks.homePage'), url: '' },
        rebateAccountLink: { name: this.$t('referralLinks.subIbReferral'), url: '' }
      },
      color: { dark: '#004cb2', light: '#ffffffff' },
      active: '',
      filename: '',
      fileUrl: '',
      showPreViewPdf: false,
      visible: false,
      signedTime: '',
      isSigned: null,
      pdfScreenLoading:false,
    };
  },
  watch: {
    accountID(newValue, oldValue) {
      if (this.accountID) this.setLinkList();

      if (oldValue != newValue) {
        this.getAgreement();
      }
    }
  },
  methods: {
    loadProgress(progress){
      if(progress.loaded === progress.total){
        this.pdfScreenLoading = false;
      }else {
        this.pdfScreenLoading = true;
      }
    },
    onCopy(item) {
      this.$copyText(item)
        .then(result => {
          this.$message(this.$t('referralLinks.alreadyCPY'));
        })
        .catch(err => {
          this.$message('Failed to copy' + err);
        });
    },
    async convertBase64ToBlob(base64) {
      const response = await fetch(base64);
      const blob = await response.blob();
      const data = [new ClipboardItem({ [blob.type]: blob })];
      await navigator.clipboard.write(data); //only https
      this.$message(this.$t('referralLinks.alreadyCPY'));
    },
    onCopyImg(name) {
      this.convertBase64ToBlob(document.getElementsByClassName(name)[0].src);
    },
    languageSelectChange(item) {
      if (this.accountID != null) this.setLinkList();
    },
    setLinkList() {
      const vm = this;
      const liveAccount = '/forex-trading-account';
      const demoAccount = '/trading/accounts/demo-account';

      function getMainDomain(type = '') {
        let domain = !vm.language ? '' : vm.language + '.';
        domain += !vm.language ? vm.GLOBAL_REFERRAL_SITE: vm.GLOBAL_REFERRAL_SITE.replace('www.', '');

        return `https://${domain}${type}/?affid=${vm.accountID}`;
      }
      function getRebateDomain(type = '') {
        let rebateDomain = !vm.language ? '' : vm.language + '.';
        rebateDomain += !vm.language
          ? vm.GLOBAL_REFERRAL_REBATE_SITE
          : vm.GLOBAL_REFERRAL_REBATE_SITE.replace('www.', '');
        return `https://${rebateDomain}/ib-registration/?affid=${vm.accountID}`;
      }

      this.linkList.liveAccountLink.url = `${getMainDomain(liveAccount)}`;
      this.linkList.demoAccountLink.url = `${getMainDomain(demoAccount)}`;
      this.linkList.homePageLink.url = `${getMainDomain()}`;
      this.linkList.rebateAccountLink.url = `${getRebateDomain()}`;
    },
    async downLoad() {
      if (this.fileUrl) {
        try {
          const response = await axios.get(this.fileUrl, { responseType: 'blob' });
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'filename.pdf';
          link.click();
        } catch (error) {
          console.error('Error downloading PDF:', error);
        }
      }
    },
    setDefaultLanuage(countryCode) {
      switch (countryCode) {
        case '1':
          this.language = this.languageList.Chinese.value;
          break;
        case '3416':
          this.language = this.languageList.Arabic.value;
          break;
        case '3915':
          this.language = this.languageList.German.value;
          break;
        case '4101':
          this.language = this.languageList.French.value;
          break;
        case '5015':
          this.language = this.languageList.Malay.value;
          break;
        case '6163':
          this.language = this.languageList.Thai.value;
          break;
        case '6581':
          this.language = this.languageList.Spain.value;
          break;
        case '6877':
          this.language = this.languageList.Indonesian.value;
          break;
        case '6991':
          this.language = this.languageList.Vietnamese.value;
          break;
        default:
          this.language = '';
      }
    },
    previewAgreement() {
      this.getAgreement();
      this.visible = true;
    },
    getAgreement() {
      if (this.accountID) {
        this.showPreViewPdf = false;
        apiAgreement(this.accountID).then(res => {
          if (res.data.code === 0 && res.data.data) {
            if (res.data.data.status === 2 && res.data.data.fileUrl) {
              this.filename = res.data.data.fileName;
              this.fileUrl = `/api` + res.data.data.fileUrl;
              this.signedTime = dataFormatter(res.data.data.signedTime);
              this.isSigned = true;
              this.showPreViewPdf = true;
            }else {
              this.isSigned = false;
            }
          } else {
            this.fileUrl = '';
            this.signedTime = '';
            this.isSigned = false;
          }
        });
      }
    }
  },
  mounted() {
    this.setDefaultLanuage(this.$store.state.common.countryCode);
    this.setLinkList();
    this.getAgreement();
    Bus.$on('updateAgreement', ()=>{
      this.getAgreement();
    })
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    isSubUser() {
      return this.$store.state.common.isSubUser;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    pdfAreaWidth() {
      let maxWidth = this.currentElDialogWidth > 852 ? 852 : this.currentElDialogWidth;
      return this.currentElDialogWidth > 768 ? maxWidth : this.currentWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referralLinks.scss';
.ib_agreement {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  @include rtl-sass-value(text-align, right, left);
  .title {
    @include rtl-sass-value(text-align, left, right);
    span {
      color: #004cb2;
      text-decoration: underline;
      line-height: 35px;
      cursor: pointer;
    }
  }
  .rebate_account {
    display: inline-block;
    margin-right: 5px;
  }
  
}
/deep/ .dialog{
  .el-dialog__body{
    padding-top: 0px;
  }
}
.agreement {
  overflow-y: scroll;
  margin: 0px;
}
.close_icon {
  text-align: right;
  cursor: pointer;
}
@media (min-width: 1500px) {
  .agreement {
    height: 600px;
  }
  .ib_agreement {
    .account {
      @include rtl-sass-value(text-align, right, left);
    }
  }
}
@media (max-width: 1300px) {
  .ib_agreement {
    .account {
      @include rtl-sass-value(text-align, left, right);
    }
  }
  .agreement {
    height: 300px;
  }
}
@media (max-width: 768px) {
  .ib_agreement {
    .account {
      margin-top: 10px;
    }
  }
}
</style>
